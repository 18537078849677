import { template as template_33f03f46fb424083a5cb05133f806fb2 } from "@ember/template-compiler";
const FKControlMenuContainer = template_33f03f46fb424083a5cb05133f806fb2(`
  <li class="form-kit__control-menu-container">
    {{yield}}
  </li>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKControlMenuContainer;
